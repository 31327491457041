import useSWR from "swr";
import { SWRResponse } from "swr/dist/types";
import { useBackend } from "../backend/BackendProvider";
import { InkType } from "../inkList";

export function useInks(): SWRResponse<InkType[], Error> {
  const backend = useBackend();
  const key = ["ink"];
  return useSWR(key, async (_, uuid) => {
    return backend.getInks();
  });
}
