import { Auth } from "@mgi-labs/mgi-id";
import { CreateInkDto, InksInfosDto } from "../domain/ink.interface";
import { InkType } from "../inkList";
import { InkPage } from "../domain/ink-page";
import { InkPageDto } from "./ink-page.dto";

export default class Backend {
  constructor(
    public readonly backendUrl: string,
    private readonly auth: Auth,
    private readonly fetch: typeof window.fetch = (...args) =>
      window.fetch(...args)
  ) {}

  async createInk({
    pn,
    productionDate,
    expirationDate,
    copyNumber,
    volume,
    inkType,
    ssd,
  }: CreateInkDto): Promise<{ sn: string; key: string; uuid: string }[]> {
    const url = this.expandURL(`ink/create`);

    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pn,
        productionDate,
        expirationDate,
        volume,
        copyNumber,
        inkType,
        ssd,
      }),
    });
    return await response.json();
  }
  async getInks() {
    const url = this.expandURL(`ink/data/allInks`);

    const response = await this.fetch(url);

    if (!response.ok) {
      throw new Error("Could not get ink from server");
    }

    return (await response.json()) as InkType[];
  }

  async getAllInks() {
    const url = this.expandURL(`ink/allCreatedInks`);
    const response = await this.fetchWithAuth(url);

    if (!response.ok) {
      throw new Error("Could not get ink from server");
    }

    return (await response.json()) as InksInfosDto[];
  }

  async exportInksExcel(lang: string) {
    const url = this.expandURL(`ink/inks-export/excel?lang=${lang}`);
    const response = await this.fetchWithAuth(url);

    if (!response.ok) {
      throw new Error("Could not get ink from server");
    }

    return response.blob();
  }

  async getInkPage(params: {
    limit: number;
    search?: string;
    cursor?: string;
    startId?: any;
    endId?: any;
  }): Promise<InkPage> {
    const searchParams: Record<string, string> = {
      limit: params.limit.toString(),
    };
    if (params.cursor) {
      searchParams["cursor"] = params.cursor;
    }
    if (params.search) {
      searchParams["search"] = params.search;
    }
    if (params.startId) {
      searchParams["startId"] = params.startId;
    }
    if (params.endId) {
      searchParams["endId"] = params.endId;
    }

    const url =
      this.expandURL(`ink/paginated-inks`) +
      "?" +
      new URLSearchParams(searchParams);
    const response = await this.fetchWithAuth(url);
    if (!response.ok) {
      throw new Error("Could not get machines from server");
    }
    const dto = (await response.json()) as InkPageDto;

    return {
      inks: dto.inks,
      metadata: dto.metadata,
    };
  }

  private async fetchWithAuth(
    input: RequestInfo,
    init: RequestInit = {}
  ): Promise<Response> {
    init.headers = new Headers(init.headers);
    init.headers.set("Authorization", `Bearer ${this.auth.token}`);
    return await this.fetch(input, init);
  }

  private expandURL(path: string): string {
    return this.backendUrl !== ""
      ? new URL(path, this.backendUrl).toString()
      : path;
  }
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NotFoundError";
  }
}
