import classNames from "classnames";
import styles from "../styles/history.module.scss";
import { useCallback, useEffect, useState } from "react";
import { InkType } from "../inkList";
import { useBackend } from "../backend/BackendProvider";
import HistoryTable from "./HistoryTable";
import { useTranslation } from "react-i18next";
import { useAuth } from "@mgi-labs/mgi-id";
import { toast } from "react-toastify";
import { useInkPages } from "../hooks/useInkPages";
import { last } from "lodash";

const INK_PAGE_SIZE = 50;

const History = () => {
  const { t, i18n } = useTranslation();

  const {
    data: pages,
    error,
    setSize,
  } = useInkPages({
    pageSize: INK_PAGE_SIZE,
  });

  const isEmpty = pages?.[0]?.inks.length === 0;
  const lastPage = last(pages);
  const isReachingEnd =
    isEmpty || (lastPage && lastPage.inks?.length < INK_PAGE_SIZE);

  const backend = useBackend();
  const [inkTypes, setInkTypes] = useState<InkType[]>();
  useEffect(() => {
    async function fetchInks() {
      const inksTypeFetch = await backend.getInks();
      setInkTypes(inksTypeFetch);
    }
    fetchInks();
  }, [backend]);

  const { auth } = useAuth();
  const handleDownloadExport = useCallback(async () => {
    if (!auth.token) {
      throw new Error("Unexpected missing auth token");
    }
    const toastId = toast.success(t("exporting"), {
      autoClose: false,
      hideProgressBar: false,
    });

    try {
      const exportInks = await backend.exportInksExcel(i18n.language);
      const href = URL.createObjectURL(exportInks);
      const link = document.createElement("a");
      link.href = href;
      const curDate = new Date();

      link.download = `export ${curDate.getFullYear()}-${curDate.getMonth()}-${curDate.getDay()}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch {
      toast.error(t("fail_export"), { autoClose: 5000 });
    } finally {
      setTimeout(() => toast.dismiss(toastId), 1000);
    }
  }, [auth.token, backend, i18n.language, t]);

  const loadMoreInks = useCallback(() => {
    setSize((size) => size + 1);
  }, [setSize]);

  return (
    <div className={classNames(styles.container, "col-12")}>
      <div className={classNames(styles.table_container)}>
        {/* TODO: show loading indicator */}
        {!pages && !error && "Loading..."}
        {error && "Failed to load data from server"}
        {pages && (
          <HistoryTable
            inks={pages.flatMap((page) => page.inks)}
            inkTypes={inkTypes}
            updateData={loadMoreInks}
            hasMore={!isReachingEnd}
          />
        )}
      </div>
      <div className={classNames(styles.export)}>
        <button
          onClick={handleDownloadExport}
          className={classNames(styles.in_button)}
        >
          {t("export")} XLS
        </button>
      </div>
    </div>
  );
};
export default History;
