export function formatDate(
  date: Date,
  locale: string,
  dateStyle: "full" | "long" | "medium" | "short" | undefined,
  time: boolean,
  seconds: boolean
): string {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    dateStyle: dateStyle,
    timeStyle: time ? (seconds ? "medium" : "short") : undefined,
  };
  const formatter = new Intl.DateTimeFormat(locale, options);
  const dateInNewTimezone = formatter.format(new Date(date));

  return dateInNewTimezone;
}
