import { TopBar } from "@mgi-labs/mgi-id";
import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import logo from "../img/Logo_Predict.svg";
import stylesHome from "../styles/home.module.scss";
const Menu = () => {
  const { i18n } = useTranslation();

  const changeLang = useCallback(
    (lang) => {
      i18n.changeLanguage(lang);
    },
    [i18n]
  );
  return (
    <>
      <div className={classNames(stylesHome.home)}></div>
      <div className={classNames(stylesHome.topbar)}>
        <TopBar
          logo={logo}
          pageName="Maintenance"
          currentLang={i18n.language}
          changeLang={changeLang}
          backendUrl={process.env["REACT_APP_BACKEND_CONNECT_URL"]}
        />
      </div>
    </>
  );
};

export default Menu;
