import download from "downloadjs";
import fontkit from "@pdf-lib/fontkit";
import {
  PDFDocument,
  StandardFonts,
  rgb,
  PDFPage,
  PDFFont,
  PDFImage,
} from "pdf-lib";
import { InkType } from "../inkList";
import QRious from "qrious";
import JsBarcode from "jsbarcode";
import {
  defaultRisks,
  fobiddenVarnish1,
  fobiddenInk1,
  fobiddenInk2,
  fobiddenVarnish2,
  fobiddenPrimer1,
  fobiddenPrimer2,
  noBarCodeLabel,
  defineImageFromUrl,
  defaultText,
} from "../interfaces/inks.interfaces";

function qrCode(url: string) {
  const canvas = document.createElement("canvas");
  new QRious({
    element: canvas,
    value: url,
    size: 450,
  });

  return canvas.toDataURL();
}

export async function LabelPdf(
  inkLabel: InkType,
  result: { sn: string; key: string; uuid: string }[],
  productionDate: Date,
  expireDate: Date,
  downloadPdf: boolean,
  count: number,
  loading: { loader: boolean; textLoader: string },
  setLoading: React.Dispatch<
    React.SetStateAction<{
      loader: boolean;
      textLoader: string;
    }>
  >
) {
  const url = noBarCodeLabel(inkLabel.PN)
    ? "/pdf/Labely.pdf"
    : "/pdf/Labelx.pdf";
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pdfDocBlank = await PDFDocument.create();

  // Define Fonts
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const helveticaFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

  const pages = pdfDoc.getPages();

  const firstPage = pages[0];

  pdfDoc.registerFontkit(fontkit);

  const fontBytesRobotoMono = await fetch(
    `${process.env.PUBLIC_URL}/RobotoMono-Regular.ttf`
  ).then((res) => res.arrayBuffer());
  const robotoMono = await pdfDoc.embedFont(fontBytesRobotoMono);

  // Function to generate Bar Codes
  async function generateBarCode(code: string): Promise<PDFImage> {
    const canvas = document.createElement("canvas");

    JsBarcode(canvas, code, {
      format: "code128",
      displayValue: false,
      marginLeft: 1,
    });
    const url = canvas.toDataURL();
    return (await defineImageFromUrl(url, pdfDoc, true)).image;
  }

  // Chinese Image only on specifics label

  const chineseImage = defineImageFromUrl(
    `${process.env.PUBLIC_URL}./img/chinese.png`,
    pdfDoc,
    true,
    0.48
  );

  // Picto image on the right of the label

  const pictoImage = defineImageFromUrl(
    getPicto(inkLabel.picto),
    pdfDoc,
    false,
    0.231
  );

  const logoHB = defineImageFromUrl(
    `${process.env.PUBLIC_URL}./img/HB.jpg`,
    pdfDoc,
    false,
    0.04
  );

  const logoHBTemp = defineImageFromUrl(
    `${process.env.PUBLIC_URL}./img/HBTemp.png`,
    pdfDoc,
    true,
    0.18
  );

  // Loop whenever there is more than one page to print
  for (let i = 1; i < count; i++) {
    const pdfDoc2 = await PDFDocument.load(existingPdfBytes);

    const [existingPage] = await pdfDoc.copyPages(pdfDoc2, [0]);
    pdfDoc.addPage(existingPage);

    const newPages = pdfDoc.getPages();
    const flagUrl =
      qrCode(
        // keep for real prod
        // `${process.env.REACT_APP_REGISTER_URL}/newactivation?sn=${
        //   result[i].sn
        // }&key=${encodeURIComponent(result[i].key)}`
        `${process.env.REACT_APP_REGISTER_URL}/validate/${result[i].uuid}`
      ) ?? `${process.env.PUBLIC_URL}./img/qrcode.png`;

    const pngImage = defineImageFromUrl(flagUrl, pdfDoc, true);

    await drawPages(
      newPages[i],
      i,
      noBarCodeLabel(inkLabel.PN) ? "" : pngImage
    );
  }
  //const [existingPage] = await pdfDocBlank.copyPages(pdfDoc, [0]);
  //pdfDocBlank.insertPage(0, existingPage);

  // Image for QR Code
  const flagUrl =
    qrCode(
      // keep for real prod
      // `${process.env.REACT_APP_REGISTER_URL}/newactivation?sn=${
      //   result[0].sn
      // }&key=${encodeURIComponent(result[0].key)}`
      `${process.env.REACT_APP_REGISTER_URL}/validate/${result[0].uuid}`
    ) ?? `${process.env.PUBLIC_URL}./img/qrcode.png`;

  const pngImage = defineImageFromUrl(flagUrl, pdfDoc, true);

  await drawPages(firstPage, 0, noBarCodeLabel(inkLabel.PN) ? "" : pngImage);

  for (let i = 0; i < pdfDoc.getPageCount(); i++) {
    const [embedPDF] = await pdfDocBlank.copyPages(pdfDoc, [i]);
    if (i % 2 === 0) {
      const page = pdfDocBlank.addPage();
      const embedPage = await pdfDocBlank.embedPage(embedPDF);
      page.drawPage(embedPage, {
        x: 0,
        y: page.getHeight() / 2,
      });
    } else {
      const embedPage = await pdfDocBlank.embedPage(embedPDF);
      const page = pdfDocBlank.getPage(Math.floor(i / 2));
      page.drawPage(embedPage, {
        x: 0,
        y: 0,
      });
    }
  }

  if (downloadPdf) {
    const pdfBytes = await pdfDocBlank.save();
    download(
      pdfBytes,
      `${inkLabel.PN}_${result[0].sn.slice(0, result[0].sn.length - 2)}.pdf`,
      "application/pdf"
    );
    setLoading({ ...loading, loader: false });
  } else {
    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    return pdfDataUri;
  }

  ////// BEGIN DRAW PDF PAGE FUNCTION

  async function drawPages(
    page: PDFPage,
    i: number,
    pngImage:
      | ""
      | Promise<{
          image: PDFImage;
          dimension:
            | {
                width: number;
                height: number;
              }
            | undefined;
        }>
  ) {
    const sn = await generateBarCode(result[i].sn);
    const key = await generateBarCode(result[i].key);

    if (inkLabel.PN === "19077S") {
      page.drawImage((await logoHB).image, {
        x: 20,
        y: 320,
        width: (await logoHB).dimension?.width,
        height: (await logoHB).dimension?.height,
      });
    }

    if (inkLabel.PN === "19077S") {
      page.drawText("Respect storage conditions", {
        x: 220,
        y: 185,
        size: 11,
        color: rgb(0, 0, 0),
      });

      page.drawImage((await logoHBTemp).image, {
        x: 260,
        y: 85,
        width: (await logoHBTemp).dimension?.width,
        height: (await logoHBTemp).dimension?.height,
      });
    }

    pngImage &&
      page.drawImage((await pngImage).image, {
        x: 125,
        y: 327,
        width: 63,
        height: 63,
      });
    pictoImage &&
      page.drawImage((await pictoImage).image, {
        x: 391.8,
        y: noBarCodeLabel(inkLabel.PN) ? 107 : 107,
        width: (await pictoImage).dimension?.width,
        height: (await pictoImage).dimension?.height,
      });

    (inkLabel.PN === "8484S" || inkLabel.PN === "18567") &&
      (await chineseImage) &&
      page.drawImage((await chineseImage).image, {
        x: 220,
        y: 85,
        width: (await chineseImage).dimension?.width,
        height: (await chineseImage).dimension?.height,
      });

    (inkLabel.PN === "16967S" ||
      inkLabel.PN === "16968S" ||
      inkLabel.PN === "18508S") &&
      page.drawText("After opening, consume within 15 days", {
        x: 75,
        y: 240,
        size: 14,
        color: rgb(1, 0, 0),
      });

    inkLabel.PN === "19077S" &&
      page.drawText("After opening, consume within 1 month", {
        x: 75,
        y: 240,
        size: 14,
        color: rgb(1, 0, 0),
      });

    (inkLabel.PN === "12985S" ||
      inkLabel.PN === "18431S" ||
      inkLabel.PN === "16967S" ||
      inkLabel.PN === "19077S" ||
      inkLabel.PN === "18508S" ||
      inkLabel.PN === "16968S") &&
      page.drawText("Stir before use", {
        x: 22,
        y: 110,
        size: 14,
        color: rgb(1, 0, 0),
      });

    page.drawText(formatDate(expireDate), {
      x: 446,
      y: 318,
      size: 11,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    page.drawRectangle({
      x: noBarCodeLabel(inkLabel.PN) ? 121.5 : 206.5,
      y: noBarCodeLabel(inkLabel.PN) ? 345.9 : 345,
      width: noBarCodeLabel(inkLabel.PN) ? 451.5 : 370,
      height: noBarCodeLabel(inkLabel.PN) ? 52 : 53,
      borderWidth: 0.001,
      color: rgbConvert(inkLabel.bgColor),
      opacity: 1,
      borderColor: rgb(0.66, 0.66, 0.68),
    });
    page.drawText(inkLabel.name ?? "JV3D EVO - UV VARNISH", {
      x: noBarCodeLabel(inkLabel.PN) ? 130 : 215,
      y: 362.5,
      size: mapLengthToSize(inkLabel.name.length),
      font: helveticaFontBold,
      color: rgbConvert(inkLabel.txtColor),
    });
    page.drawText(inkLabel.volume ?? "18 L", {
      x: inkLabel.volume.length > 4 ? 521 : 535,
      y: 318,
      size: 17,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    page.drawText(inkLabel.PN ?? "14083S", {
      x: !noBarCodeLabel(inkLabel.PN) ? 243 : 165,
      y: 318,
      size: 15,
      font: helveticaFontBold,
      color: rgb(0, 0, 0),
    });
    if (!noBarCodeLabel(inkLabel.PN)) {
      page.drawImage(sn, { x: 26.5, y: 266, width: 140, height: 20 });

      page.drawImage(key, {
        x: 211.5,
        y: 266,
        width: 140,
        height: 20,
      });
    }

    page.drawText(result[i].sn ?? "K14565A01", {
      x: noBarCodeLabel(inkLabel.PN) ? 82 : 79,
      y: noBarCodeLabel(inkLabel.PN) ? 272 : 249.5,
      size: 14,
      font: robotoMono,
      color: rgb(0, 0, 0),
    });
    !noBarCodeLabel(inkLabel.PN) &&
      page.drawText(result[i].key ?? "iyn8wFU9pzcK", {
        x: 242,
        y: 249.5,
        size: 14,
        font: robotoMono,
        color: rgb(0, 0, 0),
      });

    drawRisks(inkLabel.risk ?? defaultRisks, page, helveticaFont, inkLabel.PN);

    page.drawText(inkLabel.warning ?? "WARNING", {
      x: 395,
      y: noBarCodeLabel(inkLabel.PN) ? 95 : 95,
      size: 10,
      font: helveticaFontBold,
      color: rgb(0, 0, 0),
    });

    page.drawText(
      inkLabel.type !== "Ink"
        ? inkLabel.type === "Primer"
          ? fobiddenPrimer1
          : fobiddenVarnish1
        : fobiddenInk1,
      {
        x: 18,
        y: 68,
        size: 5.5,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    page.drawText(
      inkLabel.type !== "Ink"
        ? inkLabel.type === "Primer"
          ? fobiddenPrimer2
          : fobiddenVarnish2
        : fobiddenInk2,
      {
        x: 18,
        y: 63,
        size: 5.5,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    if (inkLabel.UN && inkLabel.UNText) {
      page.drawText(inkLabel.UN, {
        x: 20,
        y: noBarCodeLabel(inkLabel.PN) ? 220 : 200,
        size: 54,
        font: helveticaFontBold,
        color: rgb(0, 0, 0),
      });
      if (inkLabel.UNText.split(",").length > 1) {
        const splitted = inkLabel.UNText.split(",");
        page.drawText(splitted[0] + ",", {
          x: 220,
          y: noBarCodeLabel(inkLabel.PN) ? 250 : 230,
          size: 9.3,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        });
        page.drawText(splitted[1] + "," + splitted[2], {
          x: 220,
          y: noBarCodeLabel(inkLabel.PN) ? 236 : 216,
          size: 9.3,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        });
      } else {
        page.drawText(inkLabel.UNText, {
          x: 220,
          y: noBarCodeLabel(inkLabel.PN) ? 235 : 230,
          size: 9.3,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        });
      }
    }

    page.drawText(
      "\n" + drawText(inkLabel.safety).join("\n\n") ?? defaultText,
      {
        x: 19,
        y: noBarCodeLabel(inkLabel.PN) ? 215 : 197,
        size: 7,
        lineHeight: 5,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
    page.drawText(
      inkLabel.UFI === ""
        ? ""
        : "UFI : " + (inkLabel.UFI ?? "V110-N0RE-600J-3AJR"),
      {
        x: 27,
        y: 90.2,
        size: 7.5,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );
  }
}

////// END DRAW PDF PAGES FUNCTION

// Convert to RGB
function rgbConvert(color: { red: number; green: number; blue: number }) {
  return rgb(color.red / 255, color.green / 255, color.blue / 255);
}

// Function to draw Risks
function drawRisks(
  risks: string[],
  firstPage: PDFPage,
  helveticaFont: PDFFont,
  pn: string
) {
  risks.map((r, i) =>
    firstPage.drawText(r, {
      x: 395,
      y: noBarCodeLabel(pn) ? 80.2 - 9 * i : 80.2 - 9 * i,
      size: 7.5,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
  );
}

// Function to get Picto to corresponding Label
function getPicto(picto: string[]) {
  const picto1 = `${process.env.PUBLIC_URL}/img/picto/pictoGR1.jpg`;
  const picto2 = `${process.env.PUBLIC_URL}/img/picto/pictoGR2.jpg`;
  const picto3 = `${process.env.PUBLIC_URL}/img/picto/pictoGR3.jpg`;
  const picto4 = `${process.env.PUBLIC_URL}/img/picto/pictoGR4.jpg`;
  const picto5 = `${process.env.PUBLIC_URL}/img/picto/pictoGR5.jpg`;
  const picto6 = `${process.env.PUBLIC_URL}/img/picto/pictoGR6.jpg`;
  const picto7 = `${process.env.PUBLIC_URL}/img/picto/pictoGR7.jpg`;
  const picto8 = `${process.env.PUBLIC_URL}/img/picto/pictoGR8.jpg`;
  if (picto.length === 3) {
    if (picto.includes(" Corrosif")) {
      return picto2;
    } else {
      return picto4;
    }
  } else if (picto.length === 2) {
    if (
      picto.includes(" Dangereux, nocif et irritant") &&
      picto.includes("Flammable")
    ) {
      return picto7;
    } else if (
      picto.includes(" Dangereux, nocif et irritant") &&
      picto.includes(" Produits dangereux pour la santé")
    ) {
      return picto5;
    } else {
      return picto3;
    }
  } else if (picto.length === 4) {
    return picto1;
  } else if (picto.length === 1) {
    if (picto.includes(" Dangereux, nocif et irritant")) {
      return picto8;
    } else return picto6;
  } else {
    return "";
  }
}

function replaceAt(index: number, chain: string) {
  return chain.substring(0, index) + "\n\n" + chain.substring(index + 1);
}

// Function to draw text on multiple lines
function drawText(safety: string[]) {
  return safety.map((s) => {
    if (s.length > 109) {
      return s.charAt(109) === "." ? replaceAt(110, s) : replaceAt(109, s);
    } else return s;
  });
}

function mapLengthToSize(length: number) {
  if (length < 50) {
    if (length < 40) {
      if (length < 35) {
        if (length < 30) return 20;
        else {
          return 17;
        }
      } else {
        return 15;
      }
    } else {
      return 14;
    }
  } else return 12;
}

function formatDate(date: Date) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();

  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];

  const year = date.getFullYear();

  return `${day} ${monthName} ${year}`;
}
