import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles/Approuve.module.scss";

export default function NoAccessIndicator() {
  const { t } = useTranslation();
  return (
    <div className={classNames("col-12", styles.bat_modals_container)}>
      <div className={classNames("col-12", styles.bat_modals_authentificating)}>
        <div className={classNames("col-10 row", styles.bat_modal_margin2)}>
          <div className={classNames(styles.bat_modals_title, "col-12")}>
            <h5 className={classNames(styles.bat_modals_titlecontent)}>
              {t<string>("No_Access")}
            </h5>
          </div>

          <div className={classNames(styles.bat_modals_import, "col-12")}>
            {t<string>("No_Access_Message")}
          </div>
        </div>
      </div>
    </div>
  );
}
