import { useAuth, useUser } from "@mgi-labs/mgi-id";
import React from "react";
import classNames from "classnames";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Backend from "./backend/backend";
import { BackendProvider } from "./backend/BackendProvider";
import CreateLabel from "./components/Createlabel";
import Menu from "./components/Menu";
import NoAccessIndicator from "./components/NoAccessIndicator";
import History from "./components/History";
import Alert from "./components/Alert";
import Menustory from "./components/Menustory";
import styles from "./styles/home.module.scss";

function App({ backendUrl }: { backendUrl: string }) {
  const { initialized, auth } = useAuth();

  const user = useUser();
  const apps = user?.availableApps;

  if (!initialized) {
    return null;
  } else if (!auth.authenticated) {
    auth.login();
    return null;
  }

  return apps?.includes("tag-generator") ? (
    <BackendProvider value={new Backend(backendUrl, auth)}>
      <BrowserRouter>
        <Menu />
        <div className={classNames(styles.menustory)}>
          <Menustory />
        </div>
        <Routes>
          <Route path="/" element={<Navigate to="/addproduct" />} />
          <Route path="/addproduct" element={<CreateLabel />}></Route>
          <Route path="/history" element={<History />}></Route>
        </Routes>
        <Alert />
      </BrowserRouter>
    </BackendProvider>
  ) : (
    <NoAccessIndicator />
  );
}

export default App;
