export const translate = {
  en: {
    translation: {
      //Account
      Logout: "Logout",

      //Navbar

      Tag: "ADD PRODUCT",
      batch: "BATCH",
      produit: "PRODUCT",
      history: "HISTORY",

      Home: "Home",
      My_Profile: "My Profile",
      Personal_Information: "Personal Informations",
      Password: "Password",
      Methods_of_payment: "Methods of payment",
      User_management: "User Management",
      My_machines: "My Machines",
      Applications: "Applications",
      Security: "Security",

      //Home
      search: "Search",

      Welcome: ", Welcome to the MGI Connect platform !",
      New: "Come and discover what's new in",
      Click: "with a single click",
      AddUsers: "Add Users",
      Organisation: "Organisation",
      Organisation_content:
        "Manage your organisation and invite employees to join your workspace.",
      Subscriptions: "Subscriptions",
      Starter_text:
        "Congratulations you just acquired the Starter Pack Approve.BETA",
      More_informations: "More Informations",
      lasttime: "/ 24 hours",
      // used: "Already used",
      Availibleproj: "Projects",
      Number: "Number of book",
      campaigns: "Current campaigns",
      month: "/Month",
      books_used: "Books used out of",
      campaigns_sent: "Campaigns sent out of",
      projects_stored: "Projects stored on",
      Storage_space: "Storage space",
      Year: "/Year",
      projects_used: "Projects used out of",
      number_projects: "Number of projects",
      Compte_assignés: "Assigned accounts:",
      compte_sur: "accounts on",

      //My Profile
      account_inf: "Account information",
      Name: "Name",
      First_name: "First Name",
      Email: "Email Address",
      Company_adresse: "Company / Address",
      Company: "Company",
      Country: "Country",
      Address: "Address",
      Position: "Position Occupied",
      City: "City",
      CP: "C.P.",
      Photo: "Drag a profile photo here",
      Choose: "Choose a File",
      Secondary: "Secondary",
      error_save_theme: "Error while saving new theme",

      //User Managment
      status_updated: "Status has correctly been updated",
      apps_updated: "User apps has been correctly updated",
      error_save_apps: "Error while updating user apps",

      //Password
      Current_password: "Current Password",
      New_password: "New Password",
      Password_confirmation: "Password confirmation",
      Forgot: "Change your password",
      Send_email: "Reset your password",
      Send_SMS: "Verification code by text message",
      Sent_email: "An email has been sent to : ",
      Deleted_user: "has been deleted",
      Confirm: "Confirm",
      changed_pwd: "Password has correctly been changed",

      // No Access
      No_Access: "Access denied",
      No_Access_Message:
        "You do not have the rights to access this app. Please contact an administrator of your group",

      //Change status

      close: "Close",
      user_status: "User status",
      standard: "Standard",
      standard_user_description:
        "A user with standard status must receive authorization from an Admin user to access the various applications and functions of their organization.",
      admin: "Administrator",
      admin_user_description:
        "A user with an Admin status can access all of their organization's applications and functions. It also has the following (management) rights: ",
      admin_user_desc1: "Removing / Adding users",
      admin_user_desc2: "Assign status",
      apply: "Apply",
      Comptes_utilisateurs: "User account",

      // Product licence generation
      loading_product: "Creation of inks",
      loading_document: "PDF loading",

      // history
      type: "type",
      expiration_date: "expiration date",
      production_date: "production date",
      activation_date: "activation date",
      status: "status",
      created_by: "created by",
      created: "created",
      shipped: "shipped",
      received: "received",
      registered: "registered",
      activated: "activated",
      used: "used",
      ended: "ended",
      recalled: "recalled",

      export: "Export",
      exporting: "Export is starting ...",
      fail_export: "Failed while exporting",
    },
  },

  fr: {
    translation: {
      //Account
      Logout: "Se Déconnecter",

      //Navbar
      Home: "Accueil",
      Tag: "AJOUTER UN PRODUIT",
      batch: "BATCH",
      produit: "PRODUIT",
      history: "HISTORIQUE",

      My_Profile: "Mon Profil",
      Personal_Information: "Informations Personnelles",
      Password: "Mot de passe",
      Methods_of_payment: "Moyens de paiements",
      User_management: "Gestion des Utilisateurs",
      My_machines: "Mes Machines",
      Applications: "Applications",
      Security: "Sécurité",

      //Home
      search: "Rechercher",
      Welcome: ", Bienvenue sur La plateforme MGI Connect !",
      New: "Venez découvrir les nouveautés",
      Click: "d'un simple clic !",
      AddUsers: "Ajouter des utilisateurs",
      Organisation: "Organisation",
      Organisation_content:
        "Gérer votre organisation et inviter des collaborateurs à rejoindre votre espace de travail.",
      Subscriptions: "Abonnements",
      Starter_text:
        "Félicitations vous venez d’acquérir le Starter Pack Approve.BETA",
      More_informations: "Plus d'informations",
      lasttime: "/ 24 heures",
      // used: "Déjà utilisé",
      Availibleproj: "Projets",
      Number: "Nombre de book",
      campaigns: "Campagnes en cours",
      month: "/Mois",
      books_used: "Catalogues utilisés sur",
      campaigns_sent: "Campagnes envoyées sur",
      projects_stored: "Projets stockés sur",
      Storage_space: "Espace de stockage",
      Year: "/An",
      projects_used: "Projets utilisés sur",
      number_projects: "Nombre de projets",
      Compte_assignés: "Comptes assignés:",
      compte_sur: "comptes sur",

      //My Profile
      account_inf: "Information de compte",
      First_name: "Prénom",
      Email: "Adresse Mail",
      Company_adresse: "Entreprise / Adresse",
      Company: "Entreprise",
      Country: "Pays",
      Address: "Adresse",
      Position: "Poste occupé",
      City: "Ville",
      CP: "CP",
      Photo: "Faites glisser une photo de profil ici",
      Choose: "Choisir un fichier",
      Secondary: "Secondaire",
      error_save_theme: "Erreur durant la sauvegarde du nouveau theme",

      //User Managment
      status_updated: "Le statut a correctement été mis à jour",
      apps_updated:
        "Les applications de l'utilisateur ont correctement été mises à jour",
      error_save_apps:
        "Erreur durant la modifications des applications de l'utilisateur",

      //Password
      Current_password: "Mot de passe actuel",
      New_password: "Nouveau mot de passe",
      Password_confirmation: "Confirmation mot de passe",
      Forgot: "Changer votre mot de passe",
      Send_email: "Réinitialiser votre mot de passe",
      Send_SMS: "Validation du code par téléphone",
      Sent_email: "Un mail a été envoyé à l'adresse : ",
      Confirm: "Confirmer",
      Deleted_user: "a été supprimé",
      changed_pwd: "Le mot de passe a bien été changé",

      // No Access
      No_Access: "Accès refusé",
      No_Access_Message:
        "Vous n'avez pas les droits pour accèder à cette aplication. Veuillez contacter un adminstrateur de votre groupe",

      //Change status

      close: "Fermer",
      user_status: "Statut utilisateur",
      standard: "Standard",
      standard_user_description:
        "Un utilisateur disposant du statut standard doit recevoir une autorisation d’un utilisateur Admin pour accéder au différentes applications, et fonctions de son organisation.",
      admin: "Administrateur",
      admin_user_description:
        "Un utilisateur disposant d’un statut Admin peut accéder à l’ensemble des application et fonctions de son organisation. Il dispose également des droit (de gestion) suivants :",
      admin_user_desc1: "Suppression / Ajout d’utilisateurs",
      admin_user_desc2: "Assigner un statut",
      apply: "Appliquer",

      Comptes_utilisateurs: "Comptes utilisateurs",

      // Product licence generation
      loading_product: "Création des encres",
      loading_document: "Chargement du PDF",

      // history
      type: "type",
      expiration_date: "date d'expiration",
      production_date: "date de production",
      activation_date: "date d'activation",
      status: "statut",
      created_by: "créée par",
      created: "créée",
      shipped: "envoyée",
      received: "reçue",
      registered: "enregistrée",
      activated: "activée",
      used: "utilisée",
      ended: "finie",
      recalled: "recallée",

      export: "Exporter",
      exporting: "L'export a débuté ...",
      fail_export: "Erreur durant l'export",
    },
  },

  ja: {
    translation: {},
  },
};
