import classNames from "classnames";
import { ToastContainer, TypeOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import close from "../img/icons/close.svg";
import styles from "../styles/Alert.module.scss";

const Alert = () => {
  return (
    <div className={classNames(styles.alert)}>
      <div className={classNames(styles.container)}>
        <ToastContainer
          position="top-center"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          bodyClassName={classNames(styles.message)}
          toastClassName={classNames(styles.alert_box)}
          progressClassName={(context) =>
            classNames(
              mapTypeToStyle(context?.type),
              "Toastify__progress-bar Toastify__progress-bar--animated"
            )
          }
          closeButton={() => (
            <>
              <img
                src={close}
                alt=""
                className={classNames(styles.close_button)}
              />
            </>
          )}
        />
      </div>
    </div>
  );
};

export default Alert;

function mapTypeToStyle(type: TypeOptions | undefined) {
  switch (type) {
    case "success":
      return styles.progress_bar_succes;
    case "warning":
      return styles.progress_bar_warning;
    case "error":
      return styles.progress_bar_error;
    default:
      return styles.progress_bar_succes;
  }
}
