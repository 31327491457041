import React from "react";
import classNames from "classnames";
import styles from "../styles/loading.module.scss";

const Loading = ({ loadingText }: { loadingText: string }) => {
  return (
    <>
      <div className={classNames(styles.loadingzone)}>
        <div className={classNames(styles.loading)}>
          <div className={classNames(styles.ldsring)}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={classNames(styles.loadingtext)}>
            <p>{loadingText}</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Loading;
