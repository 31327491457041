import React from "react";
import { useTranslation } from "react-i18next";

import { MGILeftMenu } from "@mgi-labs/storybook-design";
import { MenuLink, homeMenu } from "./menu-list";

const Menustory = () => {
  const { t } = useTranslation();
  const currentMenu = homeMenu;

  const menuToDisplay: MenuLink[] = currentMenu.map(
    (menu: MenuLink, i: number) => {
      const newMenu: MenuLink = {
        linkleftmenu: menu.linkleftmenu,
        svg: menu.svg,
        label: t(menu.label),
        url: menu.url,
      };
      return newMenu;
    }
  );
  // Si rajout d'une doc (ce serait bien)
  //const docdashboard = {
  //   label: t("Documentation"),
  //   url: "/User_Manual_MGI_Dashboard_FR.pdf",
  // };

  return (
    <>
      <MGILeftMenu
        theme={"dark"}
        project="taggenerator"
        switchTheme={false}
        linkList={menuToDisplay}
        // docdashboard={docdashboard}
      ></MGILeftMenu>
    </>
  );
};

export default Menustory;
